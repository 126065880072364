<script setup>
import Cookies from "js-cookie";
import { useCommon } from "@/store";

const { t } = useI18n();
const config = useRuntimeConfig();
const localePath = useLocalePath();

const show = ref(false);
const hasToken = ref(false);
const isScrolled = ref(false);
const Menu = resolveComponent("SvgMenu");
const CloseMenu = resolveComponent("SvgCloseMenu");
const emits = defineEmits(["toggle"]);

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});

const links = computed(() => {
  return [
    {
      title: t("HOME"),
      routeName: localePath({ name: "index" }),
    },
    {
      title: t("PRODUCT"),
      routeName: localePath({ name: "product" }),
    },
    {
      title: t("PRICING"),
      routeName: localePath({ name: "pricing" }),
    },
    {
      title: t("ABOUT"),
      routeName: localePath({ name: "about" }),
    },
    {
      title: t("CONTACT"),
      routeName: localePath({ name: "contact" }),
    },
    {
      title: t("FAQ"),
      routeName: localePath({ name: "faq" }),
    },
  ];
});

const scrolled = () => {
  if (window.scrollY > 10) isScrolled.value = true;
  else isScrolled.value = false;
};

const resize = () => {
  if (window.innerWidth > 1024) emits("toggle", false);
};

const toggle_menu = () => {
  show.value = !show.value;
  emits("toggle", show);
};

const home = () => {
  if (show.value === true) toggle_menu();
};

const logout = () => {
  Cookies.remove("jwt");
  hasToken.value = false;
  if (show.value === true) toggle_menu();
};

onMounted(() => {
  hasToken.value = !!Cookies.get("jwt");
  window.addEventListener("scroll", scrolled);
  window.addEventListener("resize", resize);
});

onUnmounted(() => {
  window.removeEventListener("scroll", scrolled);
  window.removeEventListener("resize", resize);
});
</script>

<template>
  <div
    class="navigation"
    :class="{ scrolled: isScrolled }"
  >
    <div class="container justify-between flex">
      <div class="flex items-center">
        <nuxt-link
          :to="localePath({ name: 'index' })"
          class="flex items-center"
        >
          <logo @click="home" />
        </nuxt-link>

        <div class="links xl:flex items-center hidden ml-[28px]">
          <nuxt-link
            v-for="(link, index) in links"
            :key="index"
            class="link-item ml-5 text-base text-center leading-4 text-secondary-500 py-1 px-3 rounded-xl hover:bg-secondary-100 hover:no-underline"
            :to="link.routeName"
            :title="link.title"
          >
            {{ link.title }}
          </nuxt-link>
        </div>
      </div>
      <div class="flex items-center">
        <div v-if="showSubscribePage">
          <nuxt-link :to="localePath({ name: 'subscribe' })">
            <sc-button
              nav
              primary
              class="whitespace-nowrap"
              :label="$t('SUBSCRIBENOW')"
            />
          </nuxt-link>
        </div>

        <div v-else-if="hasToken">
          <div class="mr-6 hidden xl:inline">
            <sc-button
              nav
              outlined
              :label="$t('LOGOUT')"
              @click="logout"
            />
          </div>

          <a :href="config.public.dashboardDomain">
            <sc-button
              nav
              primary
              class="whitespace-nowrap"
              :label="$t('DASHBOARD')"
            />
          </a>
        </div>

        <div v-else>
          <div class="mr-6 hidden xl:inline">
            <nuxt-link :to="localePath({ name: 'signin' })">
              <sc-button
                nav
                outlined
                :label="$t('LOGIN')"
              />
            </nuxt-link>
          </div>

          <nuxt-link :to="localePath({ name: 'signup' })">
            <sc-button
              nav
              primary
              :label="$t('TRYITNOW')"
            />
          </nuxt-link>
        </div>

        <component
          :is="show ? CloseMenu : Menu"
          class="ml-6 xl:hidden w-6 h-6"
          @click="toggle_menu"
        />
      </div>
    </div>

    <menu-mobile 
      v-if="show"
      :links="links"
      :is-login="hasToken"
      @logout="logout"
      @toggle-menu="toggle_menu"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.navigation {
  @apply py-4 lg:py-4 bg-white border-b xl:border-b-0 border-solid border-secondary-200 sticky top-0 left-0 right-0;
  z-index: 101;
  &.scrolled {
    @apply border-b border-solid border-secondary-200;
  }
}

.links {
  .link-item {
    @apply font-normal;
    &.router-link-exact-active {
      @apply text-secondary-400 font-semibold;
    }
    &::before {
      @apply block font-semibold h-0 overflow-hidden;
      content: attr(title);
      visibility: hidden;
    }
  }
}
:deep(.sc-btn.sc-btn--primary-bordered) {
  @media (max-width: 640px) {
    @apply py-2 px-4;
    font-size: rem(15px);
    line-height: rem(16px);
  }
}
</style>
