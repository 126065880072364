<script setup>
import { useCommon } from "@/store";

const localePath = useLocalePath();

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});
</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="content py-12">
        <div class="col-span-6 md:col-span-3 lg:col-span-2">
          <nuxt-link to="/">
            <logo />
          </nuxt-link>
          <div class="hidden md:block mt-4 xl:mt-8">
            <nuxt-link
              :to="
                localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })
              "
            >
              <sc-button
                primary
                :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
              />
            </nuxt-link>
          </div>
        </div>

        <div class="pages mt-14 md:mt-4">
          <nuxt-link :to="localePath({ name: 'index' })">
            {{
              $t("HOME")
            }}
          </nuxt-link>
          <nuxt-link :to="localePath({ name: 'product' })">
            {{
              $t("PRODUCT")
            }}
          </nuxt-link>
          <nuxt-link :to="localePath({ name: 'pricing' })">
            {{
              $t("PRICING")
            }}
          </nuxt-link>
        </div>

        <div class="pages mt-14 md:mt-4">
          <nuxt-link :to="localePath({ name: 'about' })">
            {{
              $t("ABOUT")
            }}
          </nuxt-link>
          <nuxt-link :to="localePath({ name: 'contact' })">
            {{
              $t("CONTACT")
            }}
          </nuxt-link>
          <nuxt-link :to="localePath({ name: 'imprint' })">
            {{
              $t("IMPRINT")
            }}
          </nuxt-link>
        </div>

        <div class="pages md:mt-4">
          <nuxt-link :to="localePath({ name: 'faq' })">
            {{
              $t("FAQS")
            }}
          </nuxt-link>
        </div>

        <div class="mobile-social-footer small-body">
          <div class="flex items-center">
            <a
              class="text-secondary-500 small-body font-normal"
              href="sitemap.xml"
              target="_blank"
              v-text="$t('SITEMAP')"
            />
            <span class="circle" />
            <nuxt-link
              class="text-secondary-500 small-body font-normal"
              to="/"
            >
              {{ $t("PRIVACYPOLICY") }}
            </nuxt-link>
          </div>
          <div class="flex items-center justify-end">
            <a
              href="https://www.linkedin.com/company/shop-circuit/"
              target="_blank"
            >
              <svg-linkedin />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="divider" />

    <div class="container">
      <div class="mobile-footer small-body">
        <span v-text="$t('PYANGOGBMH')" />

        <div class="flex items-center justify-around mt-2">
          <span
            class="mr-1"
            v-text="$t('MADEWITH')"
          />
          <svg-heart-fill class="mx-1" />
          <a
            v-sanitize-html="$t('BYPYANGOGBMH')"
            class="small-body font-normal"
            href="https://pyango.ch/"
            target="_blank"
          />
        </div>
      </div>

      <div class="desktop-footer small-body">
        <div class="flex items-center">
          <span v-text="$t('PYANGOGBMH')" />
          <a
            class="xl:ml-6 small-body font-normal"
            href="sitemap.xml"
            target="_blank"
            v-text="$t('SITEMAP')"
          />
          <span class="circle" />
          <nuxt-link
            to="/"
            class="small-body font-normal"
          >
            {{
              $t("PRIVACYPOLICY")
            }}
          </nuxt-link>
        </div>

        <div class="col-start-2 col-end-2 lg:text-center text-right">
          <span
            class="small-body font-normal"
            v-text="$t('MADEWITH')"
          />
          <svg-heart-fill class="xl:mx-2 inline-block" />
          <a
            v-sanitize-html="$t('BYPYANGOGBMH')"
            class="small-body font-normal"
            href="https://pyango.ch/"
            target="_blank"
          />
        </div>

        <div class="hidden lg:flex items-center justify-end">
          <a
            href="https://www.linkedin.com/company/shop-circuit/"
            target="_blank"
          >
            <svg-linkedin />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  @apply bg-success-100;
}

.mobile-social-footer {
  @apply lg:hidden flex items-center justify-between col-span-6 mt-4 lg:mt-14 xl:mt-0;
}
.mobile-footer {
  @apply md:hidden py-6 flex items-center justify-center flex-col text-info-200 col-span-6;
}
.desktop-footer {
  @apply hidden md:grid lg:grid-cols-3 grid-cols-2 py-6;
}
.content {
  @apply px-0 grid grid-cols-6 gap-5 md:gap-10;
}

.logo {
  @apply flex items-center;
  span {
    @apply ml-3 text-secondary-400 text-base xl:text-2xl xl:leading-9 font-semibold;
    line-height: rem(18px);
  }
}

.pages {
  @apply col-span-3 md:col-span-1 flex flex-col;

  a {
    @apply text-base leading-4 text-secondary-500 font-normal hover:font-medium;
    &:not(:first-child) {
      @apply mt-6 md:mt-6;
    }
    &.router-link-exact-active {
      @apply font-semibold;
    }
  }
}

.divider {
  @apply h-px bg-secondary-200 col-span-6;
}

.privacy {
  @apply col-span-6 hidden xl:grid grid-cols-3 text-info-200;

  a {
    @apply text-secondary-500;
  }
}

.circle {
  @apply w-1 h-1 bg-secondary-700 rounded-full mx-2;
}
.desktop-footer,
.mobile-footer {
  a {
    @apply text-info-200;
  }
}
</style>
